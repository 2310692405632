const listeEpisodes = [
    {
        episode : 1,
        realEpisode: 52,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239133&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/nuv81is9"
            },
        ],
        dllink : "https://drive.google.com/uc?export=download&id=14vl_hhlxmlU-KOT4cJ3DG4ysiR5qZl8m"
    },
    {
        episode : 2,
        realEpisode: 53,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239134&hd=1"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/cufo36hb"
            },
        ],
        dllink : "https://drive.google.com/uc?export=download&id=18r_DBZuZPjvqX6_dCAlmqtDO2nb1sPie"
    },
    {
        episode : 3,
        realEpisode: 54,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239135&autoplay=1"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/rcm7wm50"
            },
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1YfthzZYoTxZgwJa1WAbsRIzWfJ31vJlP"
    },
    {
        episode : 4,
        realEpisode: 55,
        multi: true,
        links: [
            {
                name: "VK Video",
                link: "https://vk.com/video_ext.php?oid=852616431&id=456239136&hd=2"
            },
            {
                name: "SendVid",
                link : "https://sendvid.com/embed/db21shdp"
            },
        ],
        dllink : "https://drive.google.com/uc?export=download&id=1-2zy8APtugR6i1P52Ju7ro1tboV57Awo"
    },
    // {
    //     episode : 5,
    //     realEpisode: 56,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 6,
    //     realEpisode: 57,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 7,
    //     realEpisode: 58,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 8,
    //     realEpisode: 59,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 9,
    //     realEpisode: 60,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 10,
    //     realEpisode: 61,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 11,
    //     realEpisode: 62,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 12,
    //     realEpisode: 63,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 13,
    //     realEpisode: 64,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 14,
    //     realEpisode: 65,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 15,
    //     realEpisode: 66,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 16,
    //     realEpisode: 67,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 17,
    //     realEpisode: 68,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 18,
    //     realEpisode: 69,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 19,
    //     realEpisode: 70,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 20,
    //     realEpisode: 71,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink : ""
    // },
    // {
    //     episode : 21,
    //     realEpisode: 72,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
	// {
	//    episode : 22,
    //     realEpisode: 73,
	//     multi: true,
	//     links: [
	//         {
	//             name: "VK Video",
	//             link: ""
	//         },
	//         {
	//             name: "SendVid",
	//             link : ""
	//         },
	//     dllink: ""
	// },
    // {
    //     episode : 23,
    //     realEpisode: 74,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 24,
    //     realEpisode: 75,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
	// {
	//     episode : 25,
    //     realEpisode: 76,
	//     multi: true,
	//     links: [
	//         {
	//             name: "VK Video",
	//             link: ""
	//         },
	//         {
	//             name: "SendVid",
	//             link : ""
	//         },],
	//     dllink: ""
	// },
    // {
    //     episode : 26,
    //     realEpisode: 77,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 27,
    //     realEpisode: 78,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 28,
    //     realEpisode: 79,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 29,
    //     realEpisode: 80,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         }
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 30,
    //     realEpisode: 81,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         }
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 31,
    //     realEpisode: 82,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         }
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 32,
    //     realEpisode: 83,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         }
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 33,
    //     realEpisode: 84,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         }
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 34,
    //     realEpisode: 85,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 35,
    //     realEpisode: 86,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 36,
    //     realEpisode: 87,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 37,
    //     realEpisode: 88,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 38,
    //     realEpisode: 89,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 39,
    //     realEpisode: 90,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 40,
    //     realEpisode: 91,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 41,
    //     realEpisode: 92,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 42,
    //     realEpisode: 93,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 43,
    //     realEpisode: 94,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 44,
    //     realEpisode: 95,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 45,
    //     realEpisode: 96,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 46,
    //     realEpisode: 97,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: "2"
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 47,
    //     realEpisode: 98,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 48,
    //     realEpisode: 99,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 49,
    //     realEpisode: 100,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 50,
    //     realEpisode: 101,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
    // {
    //     episode : 51,
    //     realEpisode: 102,
    //     multi: true,
    //     links: [
    //         {
    //             name: "VK Video",
    //             link: ""
    //         },
    //         {
    //             name: "SendVid",
    //             link : ""
    //         },
    //     ],
    //     dllink: ""
    // },
]

export default listeEpisodes;